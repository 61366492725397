import React, {
	ChangeEvent,
	MutableRefObject,
	useEffect,
	useRef,
	useState
} from 'react';

interface DropdownOptionType {
	text: string;
	value: number | string;
	checked: boolean;
}

interface DropdownProps {
	text: string;
	options: Array<DropdownOptionType>;
	onChange?: Function;
	disabled?: boolean;
	selected?: Array<DropdownOptionType>;
}

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
	const dropdown: MutableRefObject<any> = useRef(null);

	// States
	const [selectedOptions, setSelectedOptions] = useState<
		DropdownOptionType[] | []
	>([]);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<DropdownOptionType[] | []>(
		props.options
	);

	// Hooks
	useEffect(() => {
		setOptions(props.options);
	}, [props.options]);
	useEffect(() => {
		const _options = [...props.options];
		_options.forEach((option) => {
			if (props.selected) {
				option.checked = props.selected.some(
					(select) => select.value === option.value
				);
			}
		});
		setOptions(_options);
	}, [props?.selected, props.options]);

	// Methods
	const closeOpenMenus = (e: MouseEvent) => {
		if (dropdown.current && open && !dropdown.current.contains(e.target)) {
			setOpen(false);
		}
	};
	const onChangeHandler = (
		e: ChangeEvent<HTMLInputElement>
	): DropdownOptionType[] | [] => {
		let _options: DropdownOptionType[] = [...selectedOptions];
		const _value = e?.target?.value;
		if (e?.target?.checked) {
			if (!selectedOptions.find((option) => option?.value === _value)) {
				const _option: DropdownOptionType = {
					text: _value,
					value: _value,
					checked: false
				};
				_options.push(_option);
			}
		} else {
			_options = selectedOptions.filter(function (option) {
				return option.value != _value;
			});
		}
		setSelectedOptions(_options);
		if (props.onChange) {
			return props.onChange(_options);
		}
		return [];
	};

	if (typeof window !== `undefined`) {
		document.addEventListener('mousedown', closeOpenMenus);
		document.addEventListener('keydown', function (e) {
			if (e.key === 'Escape') {
				setOpen(false);
			}
		});
	}

	return (
		<div ref={dropdown}>
			<button
				id="dropdownCheckboxButton"
				onClick={() => setOpen(!open)}
				data-dropdown-toggle="dropdownDefaultCheckbox"
				disabled={props?.disabled ? props.disabled : false}
				className={[
					'inline-flex min-w-[150px] flex-row items-center justify-between rounded-lg bg-white px-4 py-2.5 text-center font-sans text-sm font-medium text-body shadow hover:shadow-2xl focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:opacity-50 disabled:shadow'
				].join(' ')}
				type="button"
			>
				{props.text || 'Dropdown'}{' '}
				<svg
					className="ml-2 h-4 w-4"
					aria-hidden="true"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M19 9l-7 7-7-7"
					></path>
				</svg>
			</button>

			<div
				id="dropdownDefaultCheckbox"
				style={{ overflowY: 'scroll' }}
				className={[
					!open ? 'hidden' : null,
					'absolute z-10 mt-2 min-w-[150px] max-w-[250px] divide-y divide-gray-100 rounded-lg bg-white shadow'
				].join(' ')}
			>
				<ul
					className="max-h-[200px] space-y-3 p-3 text-sm text-gray-700"
					aria-labelledby="dropdownCheckboxButton"
				>
					{options.map((option: DropdownOptionType, i: number) => {
						return (
							<li>
								<div className="flex items-center">
									<input
										id={`${props.text
											.toLowerCase()
											.trim()}-checkbox-item-${i}`}
										type="checkbox"
										value={option.value}
										checked={option.checked}
										onChange={onChangeHandler}
										className="h-4 w-4 rounded border-gray-300 bg-white text-body focus:ring-2 focus:ring-blue-500"
									/>
									<label
										htmlFor={`${props.text
											.toLowerCase()
											.trim()}-checkbox-item-${i}`}
										className="ml-2 text-sm font-medium text-gray-900"
									>
										{option.text}
									</label>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
export default Dropdown;
export { DropdownOptionType, DropdownProps };
