import React, { useEffect, useState } from 'react';
import Layout from '../../layouts/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import smallBanner from '../../images/small-banner.jpg';
import Dropdown, {
	DropdownOptionType
} from '../../components/Dropdown/Dropdown';
import Search from '../../components/Search/Search';
import accessLogo from '../../images/access-storage.svg';
import sentinelLogo from '../../images/sentinelonblack.png';
import cubeit from '../../images/cubet-it-logo.svg';
import depotiumLogo from '../../images/depotium-logo.svg';
import flexSpaceLogo from '../../images/flex-space-logo.svg';
import arrowRight from '../../images/arrow-right-w.svg';
import storageVaultLogo from '../../images/storage-vault-lofo.svg';
import recordXLogo from '../../images/record-express-logo.svg';
import moveBuddyLogo from "../../images/movebuddy-logo.svg";
import accessResultManagementServices from "../../images/ARMSLogo.png";
let jobs: Object[] = [];

const SearchPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`)?.allSitePage?.edges;
	const indexes = [
		{ name: 'title', factor: 4 },
		{ name: 'description', factor: 1 },
		{ name: 'city', factor: 2 },
		{ name: 'reqNumber', factor: 6 },
		{ name: 'province', factor: 2 },
		{ name: 'postalCode', factor: 3 },
		{ name: 'brand', factor: 2 }
	];
	const mapLogo = {
		'Access Storage': accessLogo,
		'Access Storage/ Safe Self Storage': accessLogo,
		'Sentinel Storage': sentinelLogo,
		Sentinel: sentinelLogo,
		Depotium: depotiumLogo,
		Cubeit: cubeit,
		'Storage Vault': storageVaultLogo,
		RecordXpress: recordXLogo,
		'FlexSpace Logistics': flexSpaceLogo,
		'Storage Vault Canada': storageVaultLogo,
		'Storage Vault Canada Inc.': storageVaultLogo,
		'Move Buddy': moveBuddyLogo,
		'ARMS': accessResultManagementServices
	};
	// States
	const [displayedJobs, setDisplayedJobs] = useState<Object[] | []>(jobs);
	const [cities, setCities] = useState<Object[] | []>([]);
	const [provinces, setProvinces] = useState<Object[] | []>([]);
	const [filters, setFilters] = useState({});
	const [term, setTerm] = useState<string>('');
	const [coords, setCoords] = useState();
	const populateDropdown = (
		data: Object[],
		value: string,
		setState: Function
	) => {
		let values: DropdownOptionType[] = [];
		jobs.forEach((job: any) => {

			if (job.hasOwnProperty(value)) {
				if (!values.find((_value) => _value.value === job[value])) {
					values.push({
						text: job[value],
						value: job[value],
						checked: false
					});
				}
			}
		});
		setState(values);
	};

	const handleFilterChange = (
		filter: string,
		selectedOptions: DropdownOptionType[] | []
	) => {
		let _filters: any = { ...filters };
		_filters[filter] = selectedOptions;
		setFilters(_filters);
	};

	function dist(lat1, lon1, lat2, lon2) {
		let R = 6371; // km
		let dLat = toRad(lat2 - lat1);
		let dLon = toRad(lon2 - lon1);
		lat1 = toRad(lat1);
		lat2 = toRad(lat2);

		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2) *
			Math.cos(lat1) *
			Math.cos(lat2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;
		return d;
	}

	// Converts numeric degrees to radians
	function toRad(Value) {
		return (Value * Math.PI) / 180;
	}

	useEffect(() => {
		function getLocation() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					function setCoordinates(position) {
						const lat = position.coords.latitude;
						const long = position.coords.longitude;
						console.log('Lat: ', lat, ' Long: ', long);
						setCoords([lat, long]);
					}
				);
			}
		}

		getLocation();
	}, []);
	useEffect(() => {
		const url = new URL(window.location.href);
		const brand = url.searchParams.get('brand');
		if (brand) {
			let _filters: any = { ...filters };
			_filters.brand = [{ text: brand, value: brand }];
			setFilters(_filters);
		}
	}, []);

	useEffect(() => {
		let _tempJobs: Object[] = [];
		// Search Term
		let _term = term.toLowerCase();
		let terms: string[] = [];
		if (_term !== '') {
			// Replace punctuating between words with spaces.
			_term = _term.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ' ');

			// Split search words, delimited by spaces
			terms = _term.split(' ');

			// Remove any terms that are empty strings. ""
			terms = terms.filter((e) => e !== '');
		}

		// Query Jobs
		jobs.forEach((job: any) => {
			console.log(job.brand)
			let addJob = true;
			// Filters
			for (const [key, _filters] of Object.entries(filters)) {
				if (job.hasOwnProperty(key)) {
					if (
						_filters.find(
							(filter: any) => filter.value === job[key]
						)
					) {
						addJob = true;
					} else if (_filters.length === 0) {
						addJob = true;
					} else {
						addJob = false;
						break;
					}
				}
			}
			if (filters?.distance) {
				let _dist = filters?.distance[0].value;
				_dist = parseInt(_dist.substring(0, _dist.length - 3));
				console.log('filters?.distance: ', _dist, job.distance);
				if (job.distance < _dist) {
					addJob = true;
				}
			}

			if (terms.length > 0) {
				let indexWeight = 0;
				let data: any = job;
				// For each index
				for (let index in indexes) {
					if (indexes.hasOwnProperty(index)) {
						// Check if the jobData has that index.
						if (data.hasOwnProperty(indexes[index].name)) {
							// Make sure all terms are included in that index.
							// let allTermsIncluded = false;

							// For each search term
							for (let _term in terms) {
								if (terms.hasOwnProperty(_term)) {
									// If the index includes the term.
									if (
										data[indexes[index].name]
											.toLowerCase()
											.includes(terms[_term])
									) {
										indexWeight =
											indexWeight +
											100 * indexes[index].factor;
									}
								}
							}
						}
					}
				}
				if (indexWeight > 0) {
					data.weight = indexWeight;
					addJob = true;
				} else if (term === '') {
					addJob = true;
				} else {
					addJob = false;
				}
			}
			if (addJob) {
				_tempJobs.push(job);
			}
		});
		setDisplayedJobs(_tempJobs);
	}, [filters, term, coords]);

	useEffect(() => {
		jobs.forEach((job) => {
			job.distance = Math.round(
				dist(
					parseFloat(job?.longitude),
					parseFloat(job?.latitude),
					parseFloat(coords[0]),
					parseFloat(coords[1])
				)
			);
			console.log(
				job?.latitude,
				job?.longitude,
				coords[0],
				coords[1],
				job.distance
			);
		});
	}, [coords]);

	useEffect(() => {
		// Re-organize the data.
		let _jobs = [];
		for (let job in data) {
			if (data[job]?.node?.pageContext) {
				if (data[job]?.node?.pageContext?.title) {
					_jobs.push(data[job].node.pageContext);
				}
			}
		}
		jobs = [..._jobs];
		setDisplayedJobs(_jobs);

		// Populate dropdown fields.
		populateDropdown(_jobs, 'city', setCities);
		populateDropdown(_jobs, 'province', setProvinces);
	}, [data]);

	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		}
	];

	function descendingComparator(a, b, orderBy) {
		if (orderBy === 'distance') {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
		} else if (orderBy === 'weight') {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
		} else {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
		}

		return 0;
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	const brands = [
		{ text: 'Access Storage', value: 'Access Storage' },
		{ text: 'Sentinel Storage', value: 'Sentinel Storage' },
		{ text: 'Depotium', value: 'Depotium' },
		{ text: 'Cubeit', value: 'Cubeit' },
		{ text: 'Storage Vault', value: 'Storage Vault' },
		{ text: 'RecordXpress', value: 'RecordXpress' },
		{ text: 'FlexSpace Logistics', value: 'FlexSpace Logistics' },
		{ text: 'Storage Vault Canada', value: 'Storage Vault Canada' },
		{ text: 'Move Buddy', value: 'Move Buddy' },
		{ text: 'Access Results Management Services (ARMS)', value: 'ARMS' },
	];

	const distance = [
		{ text: '1 KM', value: '1 KM' },
		{ text: '10 KM', value: '10 KM' },
		{ text: '25 KM', value: '25 KM' },
		{ text: '50 KM', value: '50 KM' },
		{ text: '100 KM', value: '100 KM' },
		{ text: '800 KM', value: '800 KM' }
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Storage Vault Careers'}
			desc={'Storage Vault Careers'}
		>
			<section
				style={{
					backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 6e-05) 1.55%, rgba(0, 0, 0, 0.6) 100%), url('${smallBanner}')`
				}}
				className={
					'flex min-h-[265px] w-full flex-row items-end bg-cover bg-top'
				}
			>
				<div
					className={
						'container mx-auto flex h-full flex-col justify-end px-5'
					}
				>
					<a
						className={
							'mb-6 mt-6 flex w-32 flex-row rounded-full border border-white px-6 py-2.5 text-white hover:text-white hover:opacity-90'
						}
						href={'/'}
					>
						<img src={arrowRight} className={'mr-4 rotate-180'} />
						Back
					</a>
					<h1
						className={
							'font-sand py-8 text-[38px] font-bold text-white'
						}
					>
						Search for your perfect role.
					</h1>
				</div>
			</section>
			<section className={'bg-[#EEEEEE] py-12'}>
				<div className={'container mx-auto px-5'}>
					<div className={'flex flex-row flex-wrap gap-4'}>
						<div
							className={'w-full min-w-[200px] lg:max-w-[466px]'}
						>
							<Search
								onChange={(_term: string) => {
									setTerm(_term);
								}}
							/>{' '}
						</div>
						<Dropdown
							text={'City'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('city', e);
							}}
							multiple
							options={cities}
							selected={filters?.city}
						/>
						<Dropdown
							text={'Province'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('province', e);
							}}
							multiple
							options={provinces}
							selected={filters?.province}
						/>
						<Dropdown
							text={'Brand'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('brand', e);
							}}
							multiple
							options={brands}
							selected={filters?.brand}
						/>
						<Dropdown
							text={'Distance'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('distance', e);
							}}
							disabled={!coords}
							options={distance}
							selected={filters?.distance}
						/>
					</div>
				</div>
				<div
					className={
						'container mx-auto mt-6 flex flex-col gap-6 px-5'
					}
				>
					{displayedJobs
						.sort(
							getComparator(
								'asc',
								coords && coords.length > 0
									? 'distance'
									: 'weight'
							)
						)
						.map((job: any) => {
							return (
								<a
									href={job.jobPath}
									className={[
										'just flex inline-flex w-full cursor-pointer flex-col items-center rounded-lg bg-white py-5 text-left font-sans text-sm font-medium text-body shadow hover:shadow-2xl focus:outline-none focus:ring-4 focus:ring-blue-300 lg:flex-row lg:py-8 lg:px-12'
									].join(' ')}
								>
									<div
										className={
											'flex min-w-[200px] flex-row max-lg:mb-6 max-lg:justify-center'
										}
									>
										<img
											src={mapLogo[job.brand]}
											width={169}
										/>
									</div>
									<div
										className={
											'flex basis-full flex-col px-12'
										}
									>
										<span
											className={
												'mb-3 font-sans text-2xl font-bold text-pri max-lg:text-center'
											}
										>
											{job.title}
										</span>
										<p
											className={
												'font-sans  text-[18px] leading-8 text-body max-lg:text-center'
											}
										>
											{job.city}, {job.province}{' '}
											{job?.distance ? (
												<b className={'text-sm'}>
													- {job.distance} km
												</b>
											) : null}
										</p>
										<p
											className={
												'font-sans text-[18px] leading-8 text-body max-lg:text-center'
											}
										>
											{job.salary}
										</p>
										<p
											className={
												'font-sans  text-[18px] leading-8 text-body max-lg:text-center'
											}
										>
											{job.shift}
										</p>
									</div>
									<div
										className={
											'flex min-w-[200px] flex-row max-lg:mt-6 max-lg:justify-center'
										}
									>
										<button
											className={
												'rounded-full bg-pri py-3 px-10 font-sans text-lg text-white hover:opacity-90 focus:opacity-90'
											}
										>
											Apply Now
										</button>
									</div>
								</a>
							);
						})}
				</div>
			</section>
		</Layout>
	);
};

export default SearchPage;
