import React, {ChangeEvent, useState} from "react";
import {DropdownOptionType} from "../Dropdown/Dropdown";

interface SearchOptionType {
    // text: string;
    // value: number | string;
}

interface SearchProps {
    // text?: string;
    // options: Array<SearchOptionType>;
    placeholder?: string;
    onChange?: Function;
}


const Search: React.FC<SearchProps> = (props: SearchProps) => {
    const onChangeHandler = (
        e: ChangeEvent<HTMLInputElement>
    ): string => {
        if (props.onChange) {
            return props.onChange(e?.target?.value);
        }
        return "";
    };
    return (
        <div className={'w-full'}>
            <input
                id="dropdownCheckboxButton"
                placeholder={props.placeholder || 'Search'}
                data-dropdown-toggle="dropdownDefaultCheckbox"
                className={["w-full inline-flex shadow hover:shadow-2xl items-center rounded-lg bg-white px-4 py-2.5 text-left text-sm font-medium font-sans text-body focus:outline-none focus:ring-4 focus:ring-blue-300"].join(' ')}
                type="text"
                onChange={onChangeHandler}
            />
        </div>
    )
}


export default Search;